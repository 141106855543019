import * as React from "react"
import ReactPlayer from "react-player/youtube"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import { useIntl } from "gatsby-plugin-intl"

import useSceenSize from "../hooks/useScreenSize"
import Header2 from "../components/header2"
import Footer from "../components/footer"
import Seo from "../components/seo"

import faqimg1 from "../images/faq_img/trashcat.svg"
import faqimg2 from "../images/faq_img/trashimg1.png"

import faqimg_mobile1 from "../images/faq_img/plastictype1.svg"
import faqimg_mobile2 from "../images/faq_img/plastictype2.svg"
import faqimg_mobile3 from "../images/faq_img/plastictype3.svg"
import faqimg_mobile4 from "../images/faq_img/plastictype4.svg"
import faqimg_mobile5 from "../images/faq_img/plastictype5.svg"
import faqimg_mobile6 from "../images/faq_img/plastictype6.svg"
import faqimg_mobile7 from "../images/faq_img/plastictype7.svg"

const MainContainer = styled(Container)`
  padding: 0 !important;
`
const AccordionContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vh;
  padding-bottom: 7vh;
`
const TitleDiv = styled.div`
  font-size: 40px;
  color: #84b250;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
`
const Rectangle = styled.div`
  background-color: #84b250;
  width: 150px;
  min-height: 0.3vh;
  margin: 10px auto 10px;
`
const AccordionSection = styled(Container)`
  margin-top: 7vh;
  display: flex;
  justify-content: center;
`
const AccordionHeaderStyle = styled(Accordion.Header)`
  .accordion-button {
    font-size: 2vh;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
  .accordion-button:focus {
    box-shadow: none;
    background-color: #fff;
    color: #000000;
  }
  .accordion-button:not(.collapsed) {
    background-color: #84b250;
    color: #fff;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
`
const AccordionBodyStyle = styled(Accordion.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const AccordionBodyStyle2 = styled(Accordion.Body)`
  display: flex;
  flex-direction: column;
  font-size: 2vh;
  font-family: "Poppins", sans-serif;
`
const AccordionItemStyle = styled(Accordion.Item)`
  margin-bottom: 20px;
  box-shadow: 10px 5px 30px 10px rgba(0, 0, 0, 0.04);
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
`
const ImgComponent = styled.img`
  width: 80%;
  margin-bottom: 1vh;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const ImgComponent2 = styled.img`
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
`
const PlayerContainer = styled.div`
  display: flex;
  justify-content: center;
`
const AtagWithoutUnderline = styled.a`
  text-decoration: none;
`
const AccordionStyle = styled(Accordion)`
  width: 80%;
  @media (max-width: 991px) {
    width: 95%;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .accordion-item:first-of-type {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  & .accordion-button {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  & .accordion-button.collapsed {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  & .accordion-button {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`
const OlStyle = styled.ol`
  margin-left: 2vh;
`

const Faq = () => {
  const intl = useIntl()
  const { width } = useSceenSize()
  const imgArr = [
    faqimg_mobile1,
    faqimg_mobile2,
    faqimg_mobile3,
    faqimg_mobile4,
    faqimg_mobile5,
    faqimg_mobile6,
    faqimg_mobile7,
  ]

  return (
    <MainContainer fluid>
      <Seo
        title={intl.formatMessage({ id: "header_intl.faq" })}
        description={intl.formatMessage({ id: "header_intl.seo" })}
      />
      <Header2 state="mainpage" />
      <AccordionContainer>
        <TitleDiv>{intl.formatMessage({ id: "header_intl.faq" })}</TitleDiv>
        {/*<Rectangle />*/}
        <AccordionSection>
          <AccordionStyle defaultActiveKey="0">
            <AccordionItemStyle eventKey="0">
              <AccordionHeaderStyle>
                1. {intl.formatMessage({ id: "faq_q1.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle>
                {width >= 991 ? (
                  <ImgComponent src={faqimg1} alt="common types of plastic" />
                ) : (
                  imgArr.map(item => {
                    return (
                      <ImgComponent src={item} alt="common types of plastic" />
                    )
                  })
                )}
              </AccordionBodyStyle>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="1">
              <AccordionHeaderStyle>
                2. {intl.formatMessage({ id: "faq_q2.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>
                  {intl.formatMessage({ id: "faq_q2.content1.title" })}
                </span>
                <ul>
                  <li>
                    <b>
                      {intl.formatMessage({ id: "faq_q2.content1.content1" })}
                    </b>
                  </li>
                  <li>
                    <b>
                      {intl.formatMessage({ id: "faq_q2.content1.content2" })}
                    </b>
                  </li>
                  <li>
                    <b>
                      {intl.formatMessage({ id: "faq_q2.content1.content3" })}
                    </b>
                  </li>
                </ul>
                <span>{intl.formatMessage({ id: "faq_q2.content2" })}</span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="2">
              <AccordionHeaderStyle>
                3. {intl.formatMessage({ id: "faq_q3.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q3.content1" })}</span>
                <br />
                <span>{intl.formatMessage({ id: "faq_q3.content2" })}</span>
                <br />
                <PlayerContainer>
                  <ImgComponent2
                    src={faqimg2}
                    alt="identify the types of plastic"
                  />
                </PlayerContainer>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="3">
              <AccordionHeaderStyle>
                4. {intl.formatMessage({ id: "faq_q4.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q4.content1" })}</span>
                <br />
                <span>
                  {intl.formatMessage({ id: "faq_q4.content2.title" })}
                </span>
                <br />
                <span>
                  {intl.formatMessage({ id: "faq_q4.content2.content1.title" })}
                </span>
                <OlStyle>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content1.content1",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content1.content2",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content1.content3",
                    })}
                  </li>
                </OlStyle>
                <br />
                <span>
                  {intl.formatMessage({ id: "faq_q4.content2.content2.title" })}
                </span>
                <OlStyle>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content2.content1",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content2.content2",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content2.content3",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content2.content4",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q4.content2.content2.content5",
                    })}
                  </li>
                </OlStyle>
                <PlayerContainer>
                  {width >= 760 ? (
                    <ReactPlayer
                      url="https://youtu.be/N9APNi9LAw8"
                      controls="true"
                    />
                  ) : (
                    <ReactPlayer
                      url="https://youtu.be/N9APNi9LAw8"
                      controls="true"
                      width="280px"
                      height="210px"
                    />
                  )}
                </PlayerContainer>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="4">
              <AccordionHeaderStyle>
                5. {intl.formatMessage({ id: "faq_q5.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <b>{intl.formatMessage({ id: "faq_q5.content1.title" })}</b>
                <span>
                  {intl.formatMessage({ id: "faq_q5.content1.content1.title" })}
                </span>
                <OlStyle>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q5.content1.content1.content1",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q5.content1.content1.content2",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q5.content1.content1.content3",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "faq_q5.content1.content1.content4",
                    })}
                  </li>
                </OlStyle>
                <br />
                <b>{intl.formatMessage({ id: "faq_q5.content2.title" })}</b>
                <span>
                  {intl.formatMessage({ id: "faq_q5.content2.content1" })}
                </span>
                <br />
                <span>
                  {intl.formatMessage({ id: "faq_q5.content2.content2" })}
                </span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="5">
              <AccordionHeaderStyle>
                6. {intl.formatMessage({ id: "faq_q6.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q6.content1" })}</span>
                <br />
                <PlayerContainer>
                  {width >= 760 ? (
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=XyaNLLyVKqM&list=PLdsTWZjrzNf67aUmLS8r4jfhCJnsKYGZV&index=4"
                      controls="true"
                    />
                  ) : (
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=XyaNLLyVKqM&list=PLdsTWZjrzNf67aUmLS8r4jfhCJnsKYGZV&index=4"
                      controls="true"
                      width="280px"
                      height="210px"
                    />
                  )}
                </PlayerContainer>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="6">
              <AccordionHeaderStyle>
                7. {intl.formatMessage({ id: "faq_q7.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>
                  {intl.formatMessage({ id: "faq_q7.content1.title" })}
                </span>
                <br />
                <b>
                  <AtagWithoutUnderline
                    href="https://zerowastemalaysia.org/map/"
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: "faq_q7.content1.content1.title",
                    })}
                  </AtagWithoutUnderline>
                </b>
                <span>
                  {intl.formatMessage({
                    id: "faq_q7.content1.content1.content1",
                  })}
                </span>
                <br />
                <b>
                  <AtagWithoutUnderline
                    href="http://www.kakidiy.com/"
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: "faq_q7.content1.content2.title",
                    })}
                  </AtagWithoutUnderline>
                </b>
                <span>
                  {intl.formatMessage({
                    id: "faq_q7.content1.content2.content1",
                  })}
                </span>
                <br />
                <b>
                  <AtagWithoutUnderline
                    href="http://buynothingproject.org/"
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: "faq_q7.content1.content3.title",
                    })}
                  </AtagWithoutUnderline>
                </b>
                <span>
                  {intl.formatMessage({
                    id: "faq_q7.content1.content3.content1",
                  })}
                  <AtagWithoutUnderline href="https://www.facebook.com/groups/2154276071450456">
                    {intl.formatMessage({
                      id: "faq_q7.content1.content3.content1_1",
                    })}
                  </AtagWithoutUnderline>
                </span>
                <br />
                <b>
                  <AtagWithoutUnderline
                    href="https://theswapproject.com/"
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: "faq_q7.content1.content4.title",
                    })}
                  </AtagWithoutUnderline>
                </b>
                <span>
                  {intl.formatMessage({
                    id: "faq_q7.content1.content4.content1",
                  })}
                </span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="7">
              <AccordionHeaderStyle>
                8. {intl.formatMessage({ id: "faq_q8.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q8.content1" })}</span>
                <br />
                <span>{intl.formatMessage({ id: "faq_q8.content2" })}</span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="8">
              <AccordionHeaderStyle>
                9. {intl.formatMessage({ id: "faq_q9.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q9.content1" })}</span>
                <br />
                <span>{intl.formatMessage({ id: "faq_q9.content2" })}</span>
                <br />
                <span>{intl.formatMessage({ id: "faq_q9.content3" })}</span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="9">
              <AccordionHeaderStyle>
                10. {intl.formatMessage({ id: "faq_q10.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q10.content1" })}</span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="10">
              <AccordionHeaderStyle>
                11. {intl.formatMessage({ id: "faq_q11.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>
                  {intl.formatMessage({ id: "faq_q11.content1" })}{" "}
                  <AtagWithoutUnderline
                    href="https://zerowastemalaysia.org/donate/"
                    target="_blank"
                  >
                    {intl.formatMessage({ id: "faq_q11.content1_1" })}
                  </AtagWithoutUnderline>{" "}
                </span>
                <br />
                <span>
                  {intl.formatMessage({ id: "faq_q11.content2" })}{" "}
                  <AtagWithoutUnderline
                    href="mailto:info@zerowastemalaysia.org"
                    target="_blank"
                  >
                    {intl.formatMessage({ id: "faq_q11.content2_1" })}
                  </AtagWithoutUnderline>
                </span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="11">
              <AccordionHeaderStyle>
                12. {intl.formatMessage({ id: "faq_q12.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>{intl.formatMessage({ id: "faq_q12.content1" })}</span>
                <AtagWithoutUnderline
                  href="https://drive.google.com/drive/folders/1D-C75vaJzUh-eAuVZMrKOLaRDyqwvDOn?usp=sharing"
                  target="_blank"
                >
                  {intl.formatMessage({ id: "faq_q12.content2" })}
                </AtagWithoutUnderline>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
            <AccordionItemStyle eventKey="12">
              <AccordionHeaderStyle>
                13. {intl.formatMessage({ id: "faq_q13.title" })}
              </AccordionHeaderStyle>
              <AccordionBodyStyle2>
                <span>
                  {intl.formatMessage({ id: "faq_q13.content1" })}{" "}
                  <AtagWithoutUnderline
                    href="mailto:info@zerowastemalaysia.org"
                    target="_blank"
                  >
                    {intl.formatMessage({ id: "faq_q13.content1_1" })}
                  </AtagWithoutUnderline>
                </span>
              </AccordionBodyStyle2>
            </AccordionItemStyle>
          </AccordionStyle>
        </AccordionSection>
      </AccordionContainer>
      <div className="section fp-auto-height">
        <Footer state="mainpagefooter" />
      </div>
    </MainContainer>
  )
}

export default Faq
